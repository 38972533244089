import { atom } from "recoil";
import { Badge, CSC } from "@prisma/client";

export const relevantEntriesAtom = atom<(CSC & { badges: Badge[] })[]>({
  key: "relevantEntriesAtom",
  default: [],
});

export const currentEntryAtom = atom<(CSC & { badges: Badge[] }) | null>({
  key: "currentEntryAtom",
  default: null,
});

export const detailPageIsOpenAtom = atom<boolean>({
  key: "detailPageIsOpenAtom",
  default: false,
});

export const mapIsOnMobileOpenAtom = atom<boolean>({
  key: "mapIsOnMobileOpenAtom",
  default: false,
});

export const backendMapIsEmptyOnCreateAtom = atom<boolean>({
  key: "backendMapIsEmptyOnCreateAtom",
  default: false,
});

export const backendSlugIsAvailableAtom = atom<boolean>({
  key: "backendSlugIsAvailableAtom",
  default: false,
});

export const backendSlugIsNotAvailableOnCreateAtom = atom<boolean>({
  key: "backendSlugIsNotAvailableOnCreateAtom",
  default: false,
});

export const backendHeaderImageAtom = atom<File | null>({
  key: "backendHeaderImageAtom",
  default: null,
});

export const backendLogoImageAtom = atom<File | null>({
  key: "backendLogoImageAtom",
  default: null,
});

export const backendLogoIsEmptyOnCreateAtom = atom<boolean>({
  key: "backendLogoIsEmptyOnCreateAtom",
  default: false,
});

export const backendBadgeIdsAtom = atom<string[]>({
  key: "backendBadgeIdsAtom",
  default: [],
});

export const backendHeaderImageHasChanged = atom<boolean>({
  key: "backendHeaderImageHasChanged",
  default: false,
});

export const backendLogoImageHasChanged = atom<boolean>({
  key: "backendLogoImageHasChanged",
  default: false,
});
