"use client";

import { useEffect } from "react";
import { EntriesProps } from "@/interfaces";
import { useRecoilState } from "recoil";
import {
  currentEntryAtom,
  detailPageIsOpenAtom,
  mapIsOnMobileOpenAtom,
  relevantEntriesAtom,
} from "@/recoil/atoms";
import { Badge, CSC } from "@prisma/client";
import Image from "next/image";
import CadLink from "@/components/cadLink";
import { mapInstance } from "@/components/map";

export default function ListEntries(props: EntriesProps) {
  const [relevantEntries, setRelevantEntries] =
    useRecoilState(relevantEntriesAtom);
  const [, setMapIsOnMobilOpen] = useRecoilState(mapIsOnMobileOpenAtom);
  const [, setDetailPageIsOpen] = useRecoilState(detailPageIsOpenAtom);
  const [, setCurrentEntry] = useRecoilState(currentEntryAtom);

  useEffect(() => {
    let myElements = document.querySelectorAll(".ssr-list");

    for (let i = 0; i < myElements.length; i++) {
      //@ts-ignore
      myElements[i].style.display = "none";
    }

    setRelevantEntries(props.entries);
  }, []);

  const handleClickCard = (csc: CSC & { badges: Badge[] }) => {
    setDetailPageIsOpen(true);
    setCurrentEntry(csc);
    mapInstance.flyTo({
      center: csc.coords as [number, number],
      zoom: 10,
    });
  };

  const handleClickToMap = () => {
    setMapIsOnMobilOpen(true);
  };

  return (
    <>
      <div
        className="flex md:hidden rounded-lg bg-white text-green-600 h-52 font-bold p-2.5 mt-5 cursor-pointer"
        onClick={handleClickToMap}
      >
        <div className="w-52 mr-5">
          <Image src="/cscfinder.png" alt="" width={141} height={140} />
        </div>
        <div className="flex flex-col justify-start">
          <h2 className="text-2xl sm:text-3xl">Zur Map wechseln</h2>
        </div>
      </div>
      {!relevantEntries.length ? (
        <div
          className={`inline-flex w-full rounded-lg bg-white text-green-600 h-52 font-bold p-2.5 mt-5 cursor-pointer`}
        >
          <div className="w-52 mr-5">
            <Image src="/empty.jpg" alt="" width={250} height={250} />
          </div>

          <div className="flex flex-col justify-start">
            <h2 className="text-2xl sm:text-3x">Ziemlich leer hier</h2>
            <p className="text-xl">
              Hier scheint es noch keinen Club zu geben. Vielleicht eine gute
              Idee hier in der Nähe zu gründen?
            </p>
          </div>
        </div>
      ) : null}
      {relevantEntries.map((d, i) => (
        <div
          key={i}
          onClick={() => handleClickCard(d)}
          className={`inline-flex w-full rounded-lg bg-white text-green-600 h-52 font-bold p-2.5 mt-5 cursor-pointer relative ${
            relevantEntries.length - 1 === i ? "mb-12" : ""
          }`}
        >
          <div
            className="w-52 mr-5"
            style={{
              background: d.logoBGColor ?? "#FFF",
            }}
          >
            <Image
              src={d.logoURL}
              alt={`Logo von dem CSC: ${d.name}`}
              className="object-contain h-full m-auto"
              color="#000"
              width={250}
              height={250}
            />
          </div>

          <div className="block">
            <h2 className="text-2xl sm:text-3x">{d.name}</h2>

            {d.badges.find((b) => b.name === "CAD") ? <CadLink /> : null}
          </div>
        </div>
      ))}
    </>
  );
}
