"use client";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoArrowBack } from "react-icons/io5";
import Link from "next/link";
import Image from "next/image";
import { useRecoilState } from "recoil";
import { mapIsOnMobileOpenAtom } from "@/recoil/atoms";
import { useState } from "react";
const links = [
  {
    text: "Über uns",
    action: "openContent",
  },
  {
    text: "GaT Forum",
    link: "https://growandtalk.de/",
  },
  {
    text: "CAD Verband",
    link: "https://www.cad-bundesverband.de",
  },
  {
    link: "/login",
    text: "Login",
  },
  {
    link: "/datenschutzerklaerung",
    text: "Datenschutzerklärung",
  },
  {
    link: "/impressum",
    text: "Impressum",
  },
];
export default function Menu() {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [mapIsOnMobilOpen, setMapIsOnMobilOpen] = useRecoilState(
    mapIsOnMobileOpenAtom
  );

  const handleClickAction = (action: string | undefined) => {
    if (action === "openContent") {
      document
        .getElementsByClassName("content-container")[0]
        .classList.remove("hidden");
    }
  };

  const handleOnClickBackOrLogo = () => {
    if (!mapIsOnMobilOpen) return;

    setMapIsOnMobilOpen(false);
    setMenuIsOpen(false);
  };

  const handleClickMenuOnMobile = () => {
    setMenuIsOpen((prevState) => !prevState);
  };

  return (
    <>
      <nav className="hidden md:block text-green-600 bg-white">
        <div className="flex px-2.5">
          <div className="h-32 py-2">
            <Image
              className="h-100"
              src="/logo.svg"
              alt="CSC Finder Logo"
              width={140}
              height={140}
            />
          </div>
          <div className="w-full flex justify-center">
            <ul className="flex flex-auto p-2.5 max-w-7xl">
              {links.map((l, i) => {
                if (l.link) {
                  return (
                    <li key={i} className="flex items-center m-auto font-bold">
                      <Link href={l.link}>{l.text}</Link>
                    </li>
                  );
                } else {
                  return (
                    <li
                      key={i}
                      className="flex items-center m-auto cursor-pointer font-bold"
                    >
                      <span onClick={() => handleClickAction(l.action)}>
                        {l.text}
                      </span>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
          <Link
            href="/sign-in"
            className="btn btn-success flex items-center m-auto bg-green-600 hover:bg-green-600 text-white"
          >
            Kostenlose Karteneintragung!
          </Link>
        </div>
      </nav>
      <nav className="flex md:hidden px-5 justify-between  flex-wrap items-center  h-10 w-full fixed bg-green-600 text-white z-20">
        <h1 className="text-2xl font-bold" onClick={handleOnClickBackOrLogo}>
          {mapIsOnMobilOpen ? (
            <div className="flex items-center">
              <IoArrowBack className="mr-2.5" />
              <span>Zurück</span>
            </div>
          ) : (
            "CSC Finder"
          )}
        </h1>
        <button
          aria-label="Menü"
          className="w-10 text-2xl"
          onClick={handleClickMenuOnMobile}
        >
          <GiHamburgerMenu />
        </button>
        <div
          className={`fixed ${
            menuIsOpen ? "" : "hidden"
          } h-full md:hidden  w-full z-10 bg-green-900 top-10 left-0`}
        >
          <ul className="block pt-5 px-5">
            {mapIsOnMobilOpen ? (
              <li>
                <span
                  onClick={handleOnClickBackOrLogo}
                  className="block mb-5 text-xl cursor-pointer"
                >
                  Zurück zur Liste
                </span>
              </li>
            ) : null}
            {links.map((l, i) => {
              if (l.link) {
                return (
                  <Link href={l.link} className="block mb-5 text-xl" key={i}>
                    {l.text}
                  </Link>
                );
              } else {
                return (
                  <li key={i} className="block mb-5">
                    <span
                      className="text-xl cursor-pointer"
                      onClick={() => handleClickAction(l.action)}
                    >
                      {l.text}
                    </span>
                  </li>
                );
              }
            })}
            <Link className="block mb-5 text-xl" href="/sign-in">
              Kostenlose Karteneintragung!
            </Link>
          </ul>
        </div>
      </nav>
    </>
  );
}
