import Image from "next/image";
import Link from "next/link";

export default function CadLink() {
  return (
    <Link
      href="https://www.cad-bundesverband.de/"
      target="_blank"
      title="CAD Bundesverband"
      className="absolute bottom-2.5 right-2.5"
      onClick={(e) => e.stopPropagation()}
    >
      <Image src="/cadLogo.png" alt="header" width={60} height={60} />
    </Link>
  );
}
